import type {FullUser} from "@/@types/user";
import {GET, POST} from "@/rest/client";

export async function getMyUser(): Promise<FullUser|undefined> {
  try {
    return await GET<FullUser>('/users/@me', {
      mode: "web",
      full: "true"
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function acceptTerms(): Promise<boolean> {
  try {
    await POST('/users/@me/terms/accept');
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function rejectTerms(): Promise<boolean> {
  try {
    await POST('/users/@me/terms/reject');
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}