<script setup lang="ts">
import {RouterView, useRoute} from 'vue-router'
import {useGeneralStore} from "@/stores/general";
import {computed, onMounted, ref} from "vue";
import {acceptTerms, rejectTerms} from "@/rest/api/users";

const store = useGeneralStore();
const route = useRoute();

const showAcceptModal = ref(false);
const rejected = ref(false);
const loading = ref(false);
const needToAcceptTerms = computed(() => {
  return store.user && !store.user.hasAcceptedTerms;
});

onMounted(() => {
  if (needToAcceptTerms.value) {
    if (route.name !== 'website-legal') {
      showAcceptModal.value = true;
    }
  }
});

async function termsResult(accept: boolean) {
  if (loading.value) {
    return;
  }

  loading.value = true;
  try {
    if (accept) {
      if (await acceptTerms()) {
        store.user!.hasAcceptedTerms = true;
        showAcceptModal.value = false;
      }
    } else {
      if (await rejectTerms()) {
        rejected.value = true;
      }
    }
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <RouterView />

  <div v-if="showAcceptModal" class="terms-remodal">
    <h1 style="font-size: 2rem">Unsere rechtlichen Bedingungen haben sich geändert!</h1>
    <template v-if="!rejected">
      <h3>Bitte akzeptiere sie, um weiter spielen zu können.</h3>

      <router-link :to="{name: 'website-legal'}" target="_blank" style="margin-top: 1rem">
        Hier gehts zu unseren rechtlichen Bedingungen (Datenschutzerklärung, Nutzungsbedingungen)
      </router-link>

      <div style="display: flex; gap: 1rem; margin-top: 1rem">
        <button class="website--button" @click="termsResult(true)" :disabled="loading">Akzeptieren</button>
        <button class="website--outline-button danger" @click="termsResult(false)" :disabled="loading">Ablehnen</button>
      </div>
    </template>
    <template v-else>
      <h3>Schade, dass du unsere rechtlichen Bedingungen nicht akzeptieren möchtest.</h3>
      <h3>Wir haben dein Konto deaktiviert. Melde dich bei unserem Support, falls du dich umentscheidest!</h3>
    </template>
  </div>
</template>

<style scoped lang="scss">
.terms-remodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 0.5rem;
  font-size: 1.5rem;
}
</style>